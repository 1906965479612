import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import AutorenewIcon from "@mui/icons-material/Autorenew";
// constant
const icons = {
  DisplaySettingsIcon,
  ViewCarouselIcon,
  AutorenewIcon,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
  id: "all-setting",
  title: "ตั้งค่า",
  type: "group",
  children: [
    {
      id: "turn-setting",
      title: "ตั้งค่าเทิร์นในการถอน",
      type: "item",
      url: "/turn-setting",
      icon: icons.AutorenewIcon,
      breadcrumbs: false,
    },
    {
      id: "web-setting",
      title: "ตั้งค่าเว็บไซต์",
      type: "item",
      url: "/web-setting",
      icon: icons.DisplaySettingsIcon,
      breadcrumbs: false,
    },
    {
      id: "banner-setting",
      title: "ตั้งค่าแบนเนอร์",
      type: "item",
      url: "/banner",
      icon: icons.ViewCarouselIcon,
      breadcrumbs: false,
    },
    {
      id: "popup-setting",
      title: "ตั้งค่าป๊อปอัพ",
      type: "item",
      url: "/popup",
      icon: icons.ViewCarouselIcon,
      breadcrumbs: false,
    },
  ],
};

export default setting;
