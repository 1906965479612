import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";

// project import
import defaultConfig from "config";
import useLocalStorage from "hooks/useLocalStorage";

// initial state
const initialState = {
  ...defaultConfig,
  onChangeMenuType: () => {},
  onChangePresetColor: () => {},
  onChangeLocale: () => {},
  onChangeRTL: () => {},
  onChangeContainer: () => {},
  onChangeFontFamily: () => {},
  onChangeBorderRadius: () => {},
  onChangeOutlinedField: () => {},
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
  const [config, setConfig] = useLocalStorage("berry-config", {
    fontFamily: initialState.fontFamily,
    borderRadius: initialState.borderRadius,
    outlinedFilled: initialState.outlinedFilled,
    navType: initialState.navType,
    presetColor: initialState.presetColor,
    locale: initialState.locale,
    rtlLayout: initialState.rtlLayout,
  });
  const [runtimeConfig, setRuntimeConfig] = useState(null);

  // Fetch config.json once at runtime and merge with initial state
  useEffect(() => {
    fetch("/config.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load configuration");
        }
        return response.json();
      })
      .then((data) => {
        setRuntimeConfig({ ...config, ...data });
      })
      .catch((error) => {
        console.error("Error loading runtime configuration:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeMenuType = (navType) => {
    setConfig((prevConfig) => ({ ...prevConfig, navType }));
  };

  const onChangePresetColor = (presetColor) => {
    setConfig((prevConfig) => ({ ...prevConfig, presetColor }));
  };

  const onChangeLocale = (locale) => {
    setConfig((prevConfig) => ({ ...prevConfig, locale }));
  };

  const onChangeRTL = (rtlLayout) => {
    setConfig((prevConfig) => ({ ...prevConfig, rtlLayout }));
  };

  const onChangeContainer = () => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      container: !prevConfig.container,
    }));
  };

  const onChangeFontFamily = (fontFamily) => {
    setConfig((prevConfig) => ({ ...prevConfig, fontFamily }));
  };

  const onChangeBorderRadius = (event, newValue) => {
    setConfig((prevConfig) => ({ ...prevConfig, borderRadius: newValue }));
  };

  const onChangeOutlinedField = (outlinedFilled) => {
    setConfig((prevConfig) => ({ ...prevConfig, outlinedFilled }));
  };

  if (!runtimeConfig) {
    return <p>Loading configuration...</p>;
  }

  return (
    <ConfigContext.Provider
      value={{
        ...runtimeConfig,
        onChangeMenuType,
        onChangePresetColor,
        onChangeLocale,
        onChangeRTL,
        onChangeContainer,
        onChangeFontFamily,
        onChangeBorderRadius,
        onChangeOutlinedField,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

export { ConfigProvider, ConfigContext };
