import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

// constant
const icons = {
  AttachMoneyIcon,
  MoneyOffIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const depositwithdraw = {
  id: "member",
  title: "รายการฝาก-ถอน",
  type: "group",
  children: [
    {
      id: "acc-deposit",
      title: "รายการฝาก",
      type: "item",
      icon: icons.AttachMoneyIcon,
      url: "/account/deposit",
    },
    {
      id: "acc-withdraw",
      title: "รายการถอน",
      type: "item",
      icon: icons.MoneyOffIcon,
      url: "/account/withdraw",
    },
  ],
};

export default depositwithdraw;
