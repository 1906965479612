import RedeemIcon from "@mui/icons-material/Redeem";
import AddCardIcon from "@mui/icons-material/AddCard";
import AttractionsIcon from "@mui/icons-material/Attractions";
import AddLinkIcon from "@mui/icons-material/AddLink";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DiamondIcon from "@mui/icons-material/Diamond";
import PaymentsIcon from "@mui/icons-material/Payments";
import GroupIcon from "@mui/icons-material/Group";
// constant
const icons = {
  RedeemIcon,
  AddCardIcon,
  AttractionsIcon,
  AddLinkIcon,
  MilitaryTechIcon,
  GroupIcon,
  PaymentsIcon,
  MonetizationOnIcon,
  DiamondIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const promotion = {
  id: "promotion-group",
  title: "การตลาด",
  type: "group",
  children: [
    {
      id: "creditcode",
      title: "จัดการเครดิตโค้ด",
      type: "item",
      icon: icons.AddCardIcon,
      url: "/creditcode",
    },
    {
      id: "promotion",
      title: "จัดการโปรโมชั่น",
      type: "item",
      icon: icons.RedeemIcon,
      url: "/promotion",
    },
    {
      id: "wheeling",
      title: "กงล้อ/กล่องรับเพชร",
      type: "item",
      icon: icons.AttractionsIcon,
      url: "/wheeling",
    },
    {
      id: "campaign",
      title: "ลิ้งค์การตลาด",
      type: "item",
      icon: icons.AddLinkIcon,
      url: "/campaign",
    },
    {
      id: "ranking",
      title: "แรงค์กิ้ง",
      type: "item",
      icon: icons.MilitaryTechIcon,
      url: "/ranking",
    },
    {
      id: "friendinvite",
      title: "เชิญเพื่อน",
      type: "item",
      icon: icons.GroupIcon,
      url: "/friend-invite",
    },
    {
      id: "cashback",
      title: "คืนยอดเสีย",
      type: "item",
      icon: icons.PaymentsIcon,
      url: "/cashback",
    },
    //{
    //  id: "creditfree",
    //  title: "เครดิตฟรี",
    //  type: "item",
    //   icon: icons.MonetizationOnIcon,
    //   url: "/freecredit",
    // },
    // {
    //   id: "diamondfree",
    //   title: "เพชรฟรี",
    //   type: "item",
    //   icon: icons.DiamondIcon,
    //  url: "/freediamond",
    //},
  ],
};

export default promotion;
