import dashboard from "./dashboard";
import account from "./account";
import accountSummary from "./account-summary";
import member from "./member";
import promotion from "./promotion";
import api from "./api";
import setting from "./setting";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, account, member, promotion, accountSummary, api, setting],
};

export default menuItems;
