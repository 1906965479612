// material-ui
import React, { useContext } from "react";
import { Box } from "@mui/material";

// project imports
import { useDispatch, useSelector } from "store";
import Chip from "@mui/material/Chip";
// assets
//import io from "socket.io-client";
import Divider from "@mui/material/Divider";
import { getWithdrawrequestlist } from "store/slices/user";
import { SocketContext } from "../../../SocketContext";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { withdrawrequestlist } = useSelector((state) => state.user);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    socket.emit("get-client-count", "get");
    socket.on("withdraw-request-listen", () => {
      dispatch(getWithdrawrequestlist());
    });
    //socket.on("client-count", (res) => {
    //  console.log("client-count", res);
    //});

    return () => {
      socket.off("withdraw-request-listen");
      socket.off("client-count");
    };
  }, [dispatch, socket]);

  React.useEffect(() => {
    dispatch(getWithdrawrequestlist());
  }, [dispatch]);

  React.useEffect(() => {
    setData(withdrawrequestlist);
  }, [withdrawrequestlist]);

  return (
    <>
      <Divider variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" }, ml: 2.5 }}>
        <Chip
          label={data && data.length}
          color={data?.length > 0 ? "error" : "primary"}
          size="large"
          sx={{ mr: 1 }}
        />
        คำขอถอน
      </Box>
      <Divider variant="middle" flexItem sx={{ m: 2 }} />
    </>
  );
};

export default Header;
