import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// member routing หน้าลูกค้า
const MemberCustomerList = Loadable(
  lazy(() => import("views/member/customer/list"))
);

const ApiSetting = Loadable(
  lazy(() => import("views/api-setting/agent-setting/agent-profile"))
);

const BankSetting = Loadable(
  lazy(() => import("views/api-setting/bank-setting/agent-profile"))
);

const WebSetting = Loadable(lazy(() => import("views/web-setting")));

const TurnSetting = Loadable(
  lazy(() => import("views/web-setting/turnsetting"))
);

const AdminList = Loadable(lazy(() => import("views/member/admin/list")));

const AdminDetail = Loadable(
  lazy(() => import("views/member/admin/account-profile"))
);

const MemberCustomerDetail = Loadable(
  lazy(() => import("views/member/customer/account-profile"))
);

const PromotionDetail = Loadable(
  lazy(() => import("views/promotion/promotion/promotion-detail"))
);

const WhellPrize = Loadable(
  lazy(() => import("views/promotion/wheelprize/wheel-prize"))
);

const Campaign = Loadable(lazy(() => import("views/promotion/campaign")));

const Cashback = Loadable(lazy(() => import("views/promotion/cashback")));

const FriendInvite = Loadable(
  lazy(() => import("views/promotion/friendinvite"))
);

const FreeCredit = Loadable(lazy(() => import("views/promotion/freecredit")));

const FreeDiamond = Loadable(lazy(() => import("views/promotion/freediamond")));

const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

const WithdrawList = Loadable(
  lazy(() => import("views/account/withdraw/list"))
);

const DepositList = Loadable(lazy(() => import("views/account/deposit/list")));

const SummaryWithdraw = Loadable(
  lazy(() => import("views/account-summary/withdraw/list"))
);

const SummaryDeposit = Loadable(
  lazy(() => import("views/account-summary/deposit/list"))
);

const CreditCodeList = Loadable(
  lazy(() => import("views/promotion/creditcode/list"))
);

const PromotionList = Loadable(
  lazy(() => import("views/promotion/promotion/list"))
);

const Ranking = Loadable(lazy(() => import("views/promotion/ranking")));

const BannerList = Loadable(
  lazy(() => import("views/web-setting/banner/list"))
);

const BannerDetail = Loadable(
  lazy(() => import("views/web-setting/banner/banner-detail"))
);

const PopupList = Loadable(lazy(() => import("views/web-setting/popup/list")));

const PopupDetail = Loadable(
  lazy(() => import("views/web-setting/popup/popup-detail"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/wheeling",
      element: <WhellPrize />,
    },
    {
      path: "/web-setting",
      element: <WebSetting />,
    },
    {
      path: "/turn-setting",
      element: <TurnSetting />,
    },
    {
      path: "/banner",
      element: <BannerList />,
    },
    {
      path: "/banner/detail/:id",
      element: <BannerDetail />,
    },
    {
      path: "/popup",
      element: <PopupList />,
    },
    {
      path: "/popup/detail/:id",
      element: <PopupDetail />,
    },
    {
      path: "/banksetting",
      element: <BankSetting />,
    },
    {
      path: "/agentinfo",
      element: <ApiSetting />,
    },
    {
      path: "/member/list",
      element: <MemberCustomerList />,
    },
    {
      path: "/admin/list",
      element: <AdminList />,
    },
    {
      path: "/admin/profile/:id",
      element: <AdminDetail />,
    },
    {
      path: "/member/profile/:id",
      element: <MemberCustomerDetail />,
    },
    {
      path: "/promotion/detail/:id",
      element: <PromotionDetail />,
    },
    {
      path: "/account/withdraw",
      element: <WithdrawList />,
    },
    {
      path: "/account/deposit",
      element: <DepositList />,
    },
    {
      path: "/account-summary/withdraw",
      element: <SummaryWithdraw />,
    },
    {
      path: "/account-summary/deposit",
      element: <SummaryDeposit />,
    },

    {
      path: "/creditcode",
      element: <CreditCodeList />,
    },

    {
      path: "/promotion",
      element: <PromotionList />,
    },
    {
      path: "/dashboard/default",
      element: <DashboardDefault />,
    },
    {
      path: "/Campaign",
      element: <Campaign />,
    },
    {
      path: "/ranking",
      element: <Ranking />,
    },
    {
      path: "/friend-invite",
      element: <FriendInvite />,
    },
    {
      path: "/cashback",
      element: <Cashback />,
    },
    {
      path: "/freecredit",
      element: <FreeCredit />,
    },
    {
      path: "/freediamond",
      element: <FreeDiamond />,
    },
  ],
};

export default MainRoutes;
