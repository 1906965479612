// material-ui
import React from "react";
import useConfig from "hooks/useConfig";
import { useDispatch, useSelector } from "store";

import { getWebsiteSetting } from "store/slices/user";

// ==============================|| LOGO SVG ||============================== //

const Logo = (widthSet) => {
  const dispatch = useDispatch();
  const config = useConfig();
  const { websiteSetting } = useSelector((state) => state.user);
  const [previewImage, setPreviewImage] = React.useState();

  React.useEffect(() => {
    if (websiteSetting?.length > 0) {
      setPreviewImage(config.API_URL + websiteSetting[0]?.logoUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteSetting]);

  React.useEffect(() => {
    dispatch(getWebsiteSetting());
  }, [dispatch]);

  return <img src={previewImage} width={widthSet.widthSet} alt="mainlogo" />;
};

export default Logo;
