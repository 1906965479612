import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, Chip, IconButton, Divider, Stack } from "@mui/material";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import { IconMenu2 } from "@tabler/icons";
import AutorenewIcon from "@mui/icons-material/Autorenew";
//import io from "socket.io-client";

// Project Imports
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
//import MegaMenuSection from "./MegaMenuSection";
//import NotificationSection from "./NotificationSection";
import { getAgentBalance, getTMNData, getSMSBalance } from "store/slices/user";

import scbLogo from "../../../assets/images/pages/scb-logo.png";
import tmnLogo from "../../../assets/images/pages/tmn-mini-logo.webp";
// Initialize Socket

import { SocketContext } from "../../../SocketContext";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const socket = useContext(SocketContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [onlineUsers, setOnlineUsers] = useState();
  // Redux Selectors
  const { drawerOpen } = useSelector((state) => state.menu);
  const { withdrawrequestlist, agentBalance, tmnData, SMSBalance } =
    useSelector((state) => state.user);

  // Memoized Data
  const pendingWithdrawals = useMemo(() => {
    return withdrawrequestlist;
  }, [withdrawrequestlist]);

  // Handlers
  const handleClickRefresh = useCallback(() => {
    dispatch(getAgentBalance());
    dispatch(getSMSBalance());
    dispatch(getTMNData());
  }, [dispatch]);

  useEffect(() => {
    handleClickRefresh(); // Call the function once on the first render
  }, [handleClickRefresh]);

  const handleDrawerToggle = useCallback(() => {
    dispatch(openDrawer(!drawerOpen));
  }, [dispatch, drawerOpen]);

  useEffect(() => {
    // ฟังก์ชันสำหรับดึงข้อมูลผู้ใช้ออนไลน์จากเซิร์ฟเวอร์
    const fetchOnlineUsers = () => {
      socket.emit("get-online-users"); // ส่งคำขอไปที่เซิร์ฟเวอร์
    };

    // เรียกใช้งานฟังก์ชันเมื่อ component โหลดครั้งแรก
    fetchOnlineUsers();

    // ตั้งค่า interval ให้ดึงข้อมูลทุกๆ 10 นาที
    const interval = setInterval(() => {
      fetchOnlineUsers();
    }, 600000); // 10 นาที = 600,000 milliseconds

    // ฟัง event 'online-users' ที่ถูกส่งมาจากเซิร์ฟเวอร์
    socket.on("online-users", (data) => {
      setOnlineUsers(data.count);
    });

    // Cleanup interval และ event listener เมื่อ component ถูกทำลาย
    return () => {
      clearInterval(interval); // ลบ interval เมื่อ component ถูกทำลาย
      socket.off("online-users"); // ปิดการฟัง event
    };
  }, [socket]);

  // Fetch Initial Data
  /*useEffect(() => {
    dispatch(getAgentBalance());
    dispatch(getGSBData());
    dispatch(getTMNData());
  }, [dispatch]);

  // Socket Events
  useEffect(() => {
    socket.emit("get-client-count", "get");

    const withdrawListener = () => {
      setTimeout(() => {
        dispatch(getWithdrawHistory());
      }, 1000);
    };

    socket.on("withdraw-request-listen", withdrawListener);
    console.log("Header Withdraw Listen");
    return () => {
      socket.off("withdraw-request-listen", withdrawListener);
    };
  }, [dispatch]);*/

  return (
    <>
      {/* Logo & Toggler Button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: "hidden",
            transition: "all .2s ease-in-out",
            background:
              theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            "&:hover": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.light,
            },
          }}
          onClick={handleDrawerToggle}
          color="inherit"
        >
          <IconMenu2 stroke={1.5} size="20px" />
        </Avatar>
      </Box>

      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* Refresh Button */}
      <IconButton aria-label="refresh" onClick={handleClickRefresh}>
        <AutorenewIcon fontSize="small" color="primary" />
      </IconButton>

      {/* Divider */}
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />

      {/* TMN Data */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Chip
          label={tmnData?.balance ?? 0}
          color={tmnData?.balance > 20 ? "success" : "error"}
          size="large"
          sx={{ mr: 1 }}
        />
        <img src={tmnLogo} width="40" height="40" alt="scb" />
      </Stack>

      {/* Divider */}
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />

      {/* GSB Data */}

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Chip
          label={SMSBalance ?? 0}
          color={SMSBalance > 20 ? "success" : "error"}
          size="large"
          sx={{ mr: 1 }}
        />
        <img src={scbLogo} width="40" height="40" alt="scb" />
      </Stack>

      {/* Divider */}
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />

      {/* Agent Balance */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={agentBalance ?? 0}
          color={agentBalance > 1000 ? "success" : "error"}
          size="large"
          sx={{ mr: 1 }}
        />
        เครดิต
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />

      {/* Agent Balance */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={onlineUsers ?? "-"}
          color="primary"
          size="large"
          sx={{ mr: 1 }}
        />
        เข้าชม
      </Box>

      {/* Divider */}
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />

      {/* Pending Withdrawals */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Chip
          label={pendingWithdrawals.length}
          color={pendingWithdrawals.length > 0 ? "error" : "primary"}
          size="large"
          sx={{ mr: 1 }}
        />
        คำขอถอน
      </Box>

      {/* Divider */}
      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />

      {/* Notification & Profile */}

      <ProfileSection />

      {/* Mobile Header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
