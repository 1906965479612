import PostAddIcon from "@mui/icons-material/PostAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

// constant
const icons = {
  PostAddIcon,
  PlaylistRemoveIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const depositwithdraw = {
  id: "account-summary",
  title: "สรุปยอด",
  type: "group",
  children: [
    {
      id: "acc-deposit-summary",
      title: "สรุปยอดได้",
      type: "item",
      icon: icons.PostAddIcon,
      url: "/account-summary/deposit",
    },
    {
      id: "acc-withdraw-summary",
      title: "สรุปยอดเสีย",
      type: "item",
      icon: icons.PlaylistRemoveIcon,
      url: "/account-summary/withdraw",
    },
  ],
};

export default depositwithdraw;
